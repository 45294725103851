.container-header {
  display: flex;
  justify-content: space-between;  // Ajoutez cette ligne
  width: 100%;
}

.left-box,
.right-box {
  flex: 1;
  padding: 0;
  margin: 0;
}

.user-menu {
  top: 100%;
  left: 0;
  width: 100%;
  transform: translateY(15px);
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px #fafafa;

  .menu-items {
    background-color: #fff;
  }
}


.action-nav {
  max-width: 100vw!important;
  overflow-y: auto;
  .action-right {
    margin-left: auto;
  }
}

.page-title {
  font-size:2rem!important;
  margin:0!important;
  padding:0!important;
}

.card-setting {
  text-align: center;
  nb-icon {
    font-size:5rem!important;
    background-color:#3a7be0;
    width:100%;
    height:80px;
    color:#fff;
    border-radius:5px;
  }
}
