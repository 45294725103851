
.form-search {
  h3 {
    font-size:0.9rem;
    margin:0 0 0px 0!important;
    padding:0!important;
  }

  .ant-drawer-body {
    padding:0!important;
  }

  nb-card{
    margin-bottom:0!important;
  }
}


