.ant-drawer-content {
  background-color:transparent;
  background: linear-gradient(to bottom, #e0e6ef, #f6f8fc);
  padding:0px;

}

.ant-drawer-header {
  background-color:transparent;
  border-bottom:none!important;
  padding-left: 0;


  .ant-drawer-title {
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', sans-serif;
    font-size:1.3rem;
    font-weight:200;
  }
}


.form-text {
  margin-top:5px!important;
}

.form-control {
  margin-bottom:0px!important;
}

.NgxEditor__Wrapper {
  border-color:#dedede!important;
}

.overlay {
  position:absolute;
  width:100%;
  height:100%;
  cursor:progress;
}

.disabled-section {
  opacity:0.5;
  z-index:999999;
}


.min-list {
  nb-list-item {
    padding:0.5rem 0 0.5rem 0!important;
    font-size:0.8rem!important;
  }

  nb-list-item:last-child {
    border-bottom:none!important;
  }

  nb-list-item:first-child {
    border-top:none!important;
  }
}


.btn-grp-action {

  button {
    color:#000!important;
  }
  .danger  {
    background-color:#ff708d!important; color:#fff!important;
  }
}


.ant-drawer-header-title {
  padding-left:15px;
}
