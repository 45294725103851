.autocomplete {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
  padding: 1rem 0.75rem;

}

.mat-mdc-chip {
  margin-right:5px;
}

.red-color {
  color: #d23a3a;
  margin-right:10px;
}

.green-color {
  color: #81d23a;
  margin-right:10px;
}

.remove-btn {
  border-color:#dee2e6!important;
  border-top-right-radius:0.375rem!important;
  border-bottom-right-radius:0.375rem!important;
}


.skeleton-1 {
  h3 {
    margin:0px!important;
    height:30px!important;
    width:100%!important;
  }
}



.skeleton-2 {
  h3 {
    margin:0px!important;
    height:20px!important;
    width:100%!important;
  }
}

.full-width-select {
  width: 100%;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent!important;
  border:solid 1px #dee2e6;
  border-radius:5px!important;
}
