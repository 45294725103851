@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;700&display=swap');


@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "scss/grid";
@import "scss/dialog";
@import "scss/header";
@import "scss/padding";
@import "scss/table";
@import "scss/buttons";
@import "scss/drawer";
@import "scss/form";
@import "scss/search";
@import "scss/calendar";


@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';



html,
body,
body > * {
  margin: 0 !important;
  padding: 0;
}

body {
  overflow-x:hidden;
}



.app-view {
  height: 100vh;
  width: 100vw;
}

full-calendar {
  max-width: 100%!important;
  max-height: 100%!important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.relative {
  position:relative;
}


full-calendar {
  * {
    text-decoration:none!important;
  }

}


.timepicker-backdrop-overlay {
  z-index:999999!important;
}


.timepicker-overlay{
  z-index: 9999991!important;
}

.empty {
  text-align:center;
  margin:auto;
  padding:40px!important;

  nb-icon {
    margin:auto;
    font-size:3rem;
    display:block;
  }
}
.empty-0 {
  text-align:center;
  margin:auto;
  padding:3px!important;
  margin:0!important;

}


.highlighted, .selected-location {
  background-color: #fff2da !important;
}


.item-drag-drop-location {
  display: block!important;
}

.item-block {
  display: block!important;
}

.item-drag-drop-location:active,
.item-drag-drop-location:focus {
  cursor: move;
}

.item-drag-drop-location:hover {
  background-color: #ffe2ba !important;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement */
  height: 10px; /* Hauteur de la barre de défilement pour la direction verticale */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Couleur de fond de la poignée de la barre de défilement */
  border-radius: 5px; /* Pour obtenir une forme ronde */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Couleur de fond de la piste de la barre de défilement */
}

/* Optionnel: pour les barres de défilement horizontales */
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 0px; /* Si vous souhaitez que la poignée soit carrée pour les barres de défilement horizontales */
}


.sidebar {
  nb-menu {
    a {
      font-size: 0.9rem !important;
      font-weight: 500 !important;

      nb-icon {
        color: #000 !important;
      }

      &:hover {
        color: #377cd4 !important;

        nb-icon {
          color: #5c7295 !important;
        }
      }
    }

    a.active {
      color: #377cd4 !important;
      font-weight: bold!important;

      nb-icon {
        color: #5c7295 !important;
      }
    }
  }
}

.sidebarLeft {
  background-color: #1e3d99 !important;

  nb-menu {
    a {

      nb-icon {
        color: #fff !important;
      }

      &:hover {
        nb-icon {
          color: #e5f3ff !important;
        }
      }
    }

    a.active {

      nb-icon {
        color: #e5f3ff !important;
      }
    }
  }
}


.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.owl-carousel .owl-prev, .owl-carousel .owl-next {
  background: transparent;
  border: none;
  color: #000; /* Couleur des flèches */
  font-size: 24px; /* Taille de la police des flèches */
  padding: 0 10px; /* Espacement horizontal des flèches */
}

.owl-carousel .owl-prev {
  left: 10px; /* Positionnement horizontal de la flèche précédente */
}

.owl-carousel .owl-next {
  right: 10px; /* Positionnement horizontal de la flèche suivante */
}


/* Boutons actifs */
.owl-carousel .owl-prev, .owl-carousel .owl-next {
  background-color: #c49201!important;

}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}


.fc-toolbar-chunk {

  @include media-breakpoint-down(md) {
    display: block!important;
    width: 100%!important;
  }
}
@include media-breakpoint-down(md) {
  nb-layout-header nav {
    padding:0!important;
  }
}

.btn-left-back {
  position:absolute;
  right:10px;
  top:10px;
  z-index:9999;
}
