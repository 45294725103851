.first-btn {
  margin-right:10px;
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.form-control {
  padding: 5px;
  margin-bottom: 20px;
}
