.table-container {
  overflow-x:auto;
  position:relative;
  user-select: none;
}


table {
  .select-column {
    width: 40px;
    text-align: center;
  }

  tr {
    td {
      //padding: 5px 5px 0 5px !important;
      white-space: nowrap;

    }
  }

  .imageCorner {
    border-radius: 5px;
    margin: 0;
    padding: 0;
  }
}


.drag-handle {
  display: flex;
  align-items: center;
  cursor: move;

  .handle {
    margin-right: 5px;
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 4px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.body-p-0 {
  .item-body {
    padding: 0 !important;
  }
}



.translation-column {
  img {
    margin-right: 3px;
  }
}


.title-page {
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  padding-top: 15px !important;
}


.popHover {
  display: inline-block;

  &:hover {
    border-bottom: 1px dotted #000;
    cursor: help;
  }

  nb-card-header {
    * {
      font-weight:bold!important;
    }
  }
}

.pop-over {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;

  nb-card-body {
    margin: 0 !important;
    padding: 0 !important;

    nb-list-item {
      line-height: 0.4rem !important;
    }
  }


}

.empty-td {
  padding: 15px !important;
}


.breadcrumbs {
  padding:0px 0 20px 0;
  margin:0;
  a {
    color:#000;
  }
}

.flex-container {
  display: flex;
  align-items: center; /* Alignement vertical au centre */
  justify-content: flex-start; /* Alignement horizontal à gauche */
  width: 100%; /* Assurez-vous que la largeur de la cellule est utilisée */
}

.isPined  {
  background-color: #fff4ea !important;
}


.hovertr {
  &:hover {
    cursor:pointer;
  }
}
