nb-calendar {
  nb-card {
    width:100%!important;
  }
}

.date-event {

}

full-calendar {
  background-color:#fff;
  height:100%;

  a {
    color:#000!important;
  }

  .fc-scrollgrid-sync-table {
      tr:first-child {
        display: none !important;
      }
  }

  .fc .fc-timegrid-now-indicator-line {
    border-color: #000000;
    border-style: solid;
    border-width: 1px 0px 0px;
    left: 0px;
    position: absolute;
    right: 0px;
    z-index: 4;
  }


}

.fc-event, .fc-v-event{
  transform:translate(-5px, -5px);

}


.pophover-calendar-container {
  width:100%;
  height:100%;
}


.property-pophover {
  max-width:350px;
  padding:10px;

  * {
    font-size:0.8rem!important;
  }
  img {
    border-radius:5px;
  }

  h3 {
    font-size:0.9rem!important;
    padding:0!important;
    margin:0!important;
  }
}

.border-top {
  border-top:solid 1px #dedede;
}


/* Custom full calendar */
.fc-header-toolbar {
  background-color:#fff!important;
  padding:10px!important;
  margin:0!important;
  button {
    background-color:transparent!important;
    border:none!important;
  }

  .fc-button-group {
    background-color: #e1e3e8 !important;
    border-radius:25px;
    box-shadow:none!important;
    border:none!important;
    text-align:center!important;
    color:#5c5555!important;

    * {
      color:#5c5555!important;
    }
  }

  .fc-button-group, .fc-today-button {
    background-color: #e1e3e8 !important;
    border-radius:25px;
    box-shadow:none!important;
    border:none!important;
    width:100%!important;
    margin:5px 0 0 0!important;
    color: #5c5555 !important;
    font-size:0.9rem!important;

    * {
      color:#5c5555!important;
      font-size:0.9rem!important;
    }
  }

  button {
    outline: none !important;
    box-shadow: none!important;

  }

  h2 {
    font-weight:300!important;
  }
}
