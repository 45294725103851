
.p-0 {
  padding: 0!important;
}


.p-20 {
  padding: 20px;
}

.mt-2 {
  margin-top:20px;
}

.mr-5 {
  margin-right:5px;
}
